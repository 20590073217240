$color_primary: #36aca3;
$color_primary_dark: #1a717b;
$color_primary_hover: #28857f;
$color_success: #a7dd2e;
$color_info: #f59467;
$color_warning: #a97c21;
$color_warning_light: #fef8d8;
$color_danger: #1c6f9c;
$color_danger_light: #d2f5fb;
$color_primary_light: #d8faea;
$base_font_family: Poppins, sans-serif;
$secondary_font_family: Roboto, sans-serif;
$base_font_size: 14px;
$base_line_height: 1.5;
$base_border_radius: 3px;
$body_bg_color: #f5f7fa;
$table_border_color: #d3d8dd;
$light_text_color: #637381;
$medium_text_color: #666;
$dark_text_color: #1f3041;
$darkest_text_color: #32373c;
$color_error: #dc3824;
