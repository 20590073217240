@import "../../../../assets/scss/variables";

.nav-menu {

    a:not(.get-started-link) {
        color: #666;
        font-size: 14px;
    }

    .list-item:not(:last-child) {
        margin-right: 40px;
    }
}

.get-started-link {
    background-color: $color_primary;
    border: 1px solid $color_primary;
    border-radius: $base_border_radius * 2;
    box-shadow: 0 3px 12px rgba(134, 160, 158, 0.15);
    color: #fff;
    padding: 5px 18px;
    transition: all 0.2s linear;

    &:hover {
        background-color: $color_primary_hover;
        border-color: $color_primary_hover;
        color: #fff;
        text-decoration: none;
    }
}

@media (max-width: 920px) {

    .nav-menu {
        background-color: #fff;
        box-shadow: 0 3px 12px rgba(134, 160, 158, 0.15);
        flex-direction: column;
        height: fit-content;
        justify-content: flex-start;
        max-width: 414px;
        padding: 0;
        position: absolute;
        right: -9px;
        top: 32px;
        width: 100vw;
        z-index: 9;

        .list-item {
            padding: 16px;
            width: 100%;

            &:not(:last-child) {
                margin-right: 0;
            }
        }
    }

    .get-started-link {
        background-color: #fff;
        border: 0;
        box-shadow: none;
        color: $color_primary;
        padding: 0;
        text-decoration: underline;
        white-space: nowrap;
    }
}
