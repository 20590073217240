@import "./variables";

.list {
    list-style: none;
    margin: 0;
    padding-left: 0;
}

h1.title {
    color: $dark-text-color;
    font-size: 28px;
    font-weight: 300;
    line-height: $base-line-height;
}

h2.title {
    font-size: 24px;
    line-height: 1.2;
}

h3.title {
    color: $light_text_color;
    font-size: 20px;
    font-weight: 300;
    line-height: $base-line-height;
}

h4.title {
    color: $darkest-text-color;
    font-size: 18px;
    font-weight: 500;
    line-height: $base-line-height;
}

h5.title {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.625;
}

p.paragraph {
    line-height: $base-line-height;
}

.container {
    max-width: 1300px;
}

.section {
    padding: 30px 0;
    position: relative;

    &-heading {
        text-align: center;
    }

    &-title {
        margin-bottom: 6px;
    }

    &-subtitle {
        font-size: 14px;
        margin-bottom: 6px;
    }

    &-body {
        padding-top: 13px;
    }
}

.sign-up-form {
    border-radius: $base_border_radius * 2;
    margin: 0 auto;
    max-width: 400px;
    padding: 32px 24px 24px;
}

.d-flex {
    display: flex;
}

.flex-column {
    flex-direction: column;
}

.justify-content-between {
    justify-content: space-between;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-end {
    justify-content: flex-end;
}

.align-items-center {
    align-items: center;
}

.bg-white {
    background-color: #fff;
}

.with-shadow {
    box-shadow: 0 3px 12px rgba(134, 160, 158, 0.15);
}

.fixed {
    max-width: inherit;
    position: fixed;
    width: inherit;
}

.sign-up-form-wrapper {
    border-radius: $base_border_radius * 2;
    margin: 0 auto;
    max-width: 400px;
    padding: 32px 24px 24px;
}

.form-row {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
}

.list-pricing-plans {

    .list-item {

        &:first-child,
        &:last-child {

            .card-subtitle {
                max-width: 190px;
            }
        }
    }
}

.statistic {

    &-list {
        padding: 21px 8px 16px;
    }

    &-item:not(:last-child) {
        border-right: 1px solid #ececec;
    }
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}

.row-with-5-col {

    .col {
        padding: 0 15px;
        width: 20%;
    }
}

.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl {
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    width: 100%;
}

@media (min-width: 576px) {

    .col-sm-1 {
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }

    .col-sm-2 {
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }

    .col-sm-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-sm-4 {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .col-sm-5 {
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }

    .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-sm-7 {
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }

    .col-sm-8 {
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }

    .col-sm-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-sm-10 {
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }

    .col-sm-11 {
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }

    .col-sm-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media (min-width: 769px) {

    .col-md-1 {
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }

    .col-md-2 {
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }

    .col-md-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-md-4 {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .col-md-5 {
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }

    .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-md-7 {
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }

    .col-md-8 {
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }

    .col-md-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-md-10 {
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }

    .col-md-11 {
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }

    .col-md-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media (min-width: 992px) {

    .col-lg-1 {
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }

    .col-lg-2 {
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }

    .col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-lg-4 {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .col-lg-5 {
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }

    .col-lg-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-lg-7 {
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }

    .col-lg-8 {
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }

    .col-lg-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-lg-10 {
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }

    .col-lg-11 {
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }

    .col-lg-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media (max-width: 768px) {

    h1.title {
        font-size: 25px;
    }

    h2.title {
        font-size: 22px;
    }

    h3.title {
        font-size: 18px;
    }

    h4.title {
        font-size: 16px;
    }

    h5.title {
        font-size: 16px;
    }

    .row-with-5-col {
        flex-wrap: wrap;

        .col {
            margin-bottom: 16px;
            width: 33.33%;

            &:nth-child(4) {
                margin-bottom: 0;
                width: 50%;
            }

            &:nth-child(5) {
                margin-bottom: 0;
                width: 50%;
            }
        }
    }

    .col-md-3 {

        &.statistic-item {
            width: 50%;

            &:first-child,
            &:nth-child(2) {
                border-bottom: 1px solid #ececec;
            }
        }
    }
}

@media (max-width: 480px) {

    h1.title {
        font-size: 20px;
    }

    .section {
        padding-top: 0;

        &-body {
            padding-top: 0;
        }
    }

    h3.title {
        font-size: 16px;
    }

    p {
        line-height: 1.2;
    }
}
