@import "../../../assets/scss/variables";

.panel {
    max-width: inherit;
    padding: 10px;
    position: relative;
    width: inherit;
    z-index: 99;

    &-left {
        align-items: center;
    }

    &-right {

        p {
            color: #666;
            font-size: 16px;
            margin-bottom: 0;
            margin-top: 0;
        }
    }

    &-title {
        align-items: center;
        color: #165362;
        margin-bottom: 0;
        margin-left: 12px;
        margin-top: 0;

        &:before {
            background-color: $color-primary;
            content: "";
            display: inline-flex;
            height: 16px;
            margin-right: 12px;
            width: 2px;
        }
    }

    &-content {
        margin: 0 auto;
        max-width: 1280px;
        position: relative;
    }
}

.toggle-nav {
    cursor: pointer;
    display: none;
    height: 16px;
    margin-left: auto;
    margin-right: 22px;
    position: relative;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    width: 19px;
    z-index: 99;

    span {
        background: $color_primary;
        display: block;
        height: 2px;
        left: 0;
        opacity: 1;
        position: absolute;
        transform: rotate(0deg);
        transition: 0.25s ease-in-out;
        width: 100%;

        &:nth-child(1) {
            top: 0;
            transform-origin: left center;
        }

        &:nth-child(2) {
            top: 6px;
            transform-origin: left center;
        }

        &:nth-child(3) {
            top: 12px;
            transform-origin: left center;
        }
    }

    &.active {

        span {

            &:nth-child(1) {
                left: 8px;
                top: 0;
                transform: rotate(45deg);
            }

            &:nth-child(2) {
                opacity: 0;
                width: 0;
            }

            &:nth-child(3) {
                left: 8px;
                top: 13px;
                transform: rotate(-45deg);
            }
        }
    }
}

@media (max-width: 920px) {

    .toggle-nav {
        display: block;
    }

    .nav-wrapper {
        transform: translateX(500px);
        transition: all 400ms ease-in-out;

        &.open {
            transform: translateX(0);
            transition: all 400ms ease-in-out;
        }

    }
}

@media (max-width: 480px) {

    .panel {
        margin: 0 -10px;
        width: auto;
    }
}
