@import "../../../assets/scss/variables";

.button {
    align-items: center;
    background-color: transparent;
    border: 1px solid $color-primary;
    border-radius: $base-border-radius * 2;
    color: $color_primary;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    justify-content: center;
    line-height: 1;
    max-width: 351px;
    padding: 16px;
    transition: all 0.2s linear;
    width: 100%;

    &:hover {
        background-color: $color_primary;
        color: #fff;
    }

    &:focus {
        outline: none;
    }
}
