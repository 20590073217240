@import "./variables";

* {
    box-sizing: border-box;
    scroll-behavior: smooth;

    &:focus {
        outline: none;
    }
}

body {
    background-color: $body-bg-color;
    color: $light-text-color;
    font-family: $base-font-family;
    font-size: $base-font-size;
    font-weight: normal;
    line-height: $base-line-height;
}

.list {
    list-style: none;
    margin: 0;
    padding-left: 0;
}

h1 {
    color: $dark-text-color;
    font-size: 28px;
    font-weight: 300;
    line-height: $base-line-height;
}

h2 {
    font-size: 24px;
    line-height: 1.2;
}

h3 {
    font-size: 20px;
    line-height: $base-line-height;
}

h4 {
    color: $darkest-text-color;
    font-size: 18px;
    font-weight: 500;
    line-height: $base-line-height;
}

h5 {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.625;
}

p {
    font-size: $base-font-size;
    line-height: $base-line-height;
}

img {
    height: auto;
    max-width: 100%;
    object-fit: contain;
}

a {
    color: $color_primary;
    text-decoration: none;

    &:hover {
        color: $color_primary;
        text-decoration: underline;
    }
}

.container {
    margin: 0 auto;
    max-width: unset;
    overflow: hidden;
    padding: 0 20px;
    width: 100%;
}

.fixed {
    background-color: #fff;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 1280px;
    position: fixed;
    right: 0;
    width: inherit;
    z-index: 9;
}

.App {
    margin: 0 auto;
    width: 100%;
}

.welcome-section-wrapper {
    margin: 0 auto;
    max-width: 1280px;
    padding-top: 99px;
}

//CustomeSelect

.selectReg {

    .react-select__control {
        appearance: none;
        background-color: #fff;
        background-image:
            linear-gradient(
                45deg,
                transparent 50%,
                $light_text_color 50%
            ),
            linear-gradient(135deg, $light_text_color 50%, transparent 50%);
        background-position:
            calc(100% - 20px) calc(1em + 8px),
            calc(100% - 15px) calc(1em + 8px),
            calc(100% - 2.5em) 0.5em;
        background-repeat: no-repeat;
        background-size: 5px 5px, 5px 5px, 1px 1.5em;
        border: 1px solid #8996a2 !important;
        border-radius: $base_border_radius * 2 !important;
        color: $light_text_color;
        font-size: 14px;
        font-weight: normal;
        height: 48px;
        padding: 8px 16px;

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &-webkit-autofill:focus,
        &-webkit-autofill,
        &-webkit-autofill:hover,
        &-webkit-autofill:focus,
        &-webkit-autofill,
        &-webkit-autofill:hover,
        &-webkit-autofill:focus {
            border: 1px solid #8996a2 !important;
            transition: background-color 5000s ease-in-out 0s !important;
        }

        &:focus {
            border-color: $color_primary;
        }
    }
}

.selectReg {

    .react-select__control--is-focused {
        appearance: none;
        background-color: #fff !important;
        background-image:
            linear-gradient(
                45deg,
                transparent 50%,
                $light_text_color 50%
            ),
            linear-gradient(135deg, $light_text_color 50%, transparent 50%);
        background-position:
            calc(100% - 20px) calc(1em + 8px),
            calc(100% - 15px) calc(1em + 8px),
            calc(100% - 2.5em) 0.5em;
        background-repeat: no-repeat;
        background-size: 5px 5px, 5px 5px, 1px 1.5em;
        border: 1px solid #8996a2 !important;
        border-radius: $base_border_radius * 2 !important;
        box-shadow: none !important;
        color: $light_text_color !important;
        font-size: 14px;
        font-weight: normal;
        height: 48px;
        padding: 8px 16px;

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &-webkit-autofill:focus,
        &-webkit-autofill,
        &-webkit-autofill:hover,
        &-webkit-autofill:focus,
        &-webkit-autofill,
        &-webkit-autofill:hover,
        &-webkit-autofill:focus {
            border: 1px solid #8996a2 !important;
            transition: background-color 5000s ease-in-out 0s !important;
        }

        &:focus {
            border-color: $color_primary !important;
        }
    }
}

.selectSet {

    .react-select__control--is-focused {
        appearance: none;
        background-color: #fff !important;
        background-image:
            linear-gradient(
                45deg,
                transparent 50%,
                $light_text_color 50%
            ),
            linear-gradient(135deg, $light_text_color 50%, transparent 50%);
        background-position:
            calc(100% - 20px) calc(1em + 4px),
            calc(100% - 15px) calc(1em + 4px),
            calc(100% - 2.5em) 0.5em;
        background-repeat: no-repeat;
        background-size: 5px 5px, 5px 5px, 1px 1.5em;
        border: 1px solid #8996a2 !important;
        border-radius: $base_border_radius * 2 !important;
        box-shadow: none !important;
        color: $light_text_color !important;
        font-size: 14px;
        font-weight: normal;
        height: 40px;
        padding: 4px 16px;
        width: 152px;

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &-webkit-autofill:focus,
        &-webkit-autofill,
        &-webkit-autofill:hover,
        &-webkit-autofill:focus,
        &-webkit-autofill,
        &-webkit-autofill:hover,
        &-webkit-autofill:focus {
            border: 1px solid #8996a2 !important;
            transition: background-color 5000s ease-in-out 0s !important;
        }

        &:focus {
            border-color: $color_primary !important;
        }
    }
}

.selectSet {

    .react-select__control {
        appearance: none;
        background-color: #fff !important;
        background-image:
            linear-gradient(
                45deg,
                transparent 50%,
                $light_text_color 50%
            ),
            linear-gradient(135deg, $light_text_color 50%, transparent 50%);
        background-position:
            calc(100% - 20px) calc(1em + 4px),
            calc(100% - 15px) calc(1em + 4px),
            calc(100% - 2.5em) 0.5em;
        background-repeat: no-repeat;
        background-size: 5px 5px, 5px 5px, 1px 1.5em;
        border: 1px solid #8996a2 !important;
        border-radius: $base_border_radius * 2 !important;
        box-shadow: none !important;
        color: $light_text_color !important;
        font-size: 14px;
        font-weight: normal;
        height: 40px;
        padding: 4px 16px;
        width: 152px;

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &-webkit-autofill:focus,
        &-webkit-autofill,
        &-webkit-autofill:hover,
        &-webkit-autofill:focus,
        &-webkit-autofill,
        &-webkit-autofill:hover,
        &-webkit-autofill:focus {
            border: 1px solid #8996a2 !important;
            transition: background-color 5000s ease-in-out 0s !important;
        }

        &:focus {
            border-color: $color_primary !important;
        }
    }
}

.settings-table {

    .react-select__control {
        border: 0 !important;
    }

    .style-input {
        border: 0 !important;
        height: 40px;
    }
}

.react-select__indicator-separator,
.react-select__indicators {
    display: none !important;
}

.customeSelect .react-select__value-container {
    margin-left: 0;
    padding-left: 0;
}

.select-error .react-select__control {
    border-color: $color_error !important;
    color: $color_error;
}

.error-block {
    color: $color_error;
    font-size: 12px;
    font-weight: normal;
    line-height: 18px;
}

@media (max-width: 920px) {

    .welcome-section-wrapper {
        padding-top: 47px;
    }
}

@media (max-width: 767px) {

    h1 {
        font-size: 25px;
    }

    h2 {
        font-size: 22px;
    }

    h3 {
        font-size: 18px;
    }

    h4 {
        font-size: 16px;
    }

    h5 {
        font-size: 15px;
    }

    .container {
        padding: 0 10px;
    }
}

@media (max-width: 480px) {

    .fixed {
        width: 100%;
    }

    .welcome-section-wrapper {
        margin: 0 -10px;
    }

    p {
        line-height: 1.2;
    }
}
