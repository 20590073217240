@import "../../../assets/scss/variables";

.switch-container {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 4px;
    position: relative;
}

.active {
    color: $color_primary;
}

.option-title {
    color: $light_text_color;
    font-weight: 600;
    margin-bottom: 4px;
}

.option-label {
    margin-right: 16px;
}

.switch {
    opacity: 0;
    position: absolute;

    + div {
        background-color: #f3f3f3;
        border-radius: 999px;
        cursor: pointer;
        transition-duration: 0.3s;
        transition-property: background-color, box-shadow;
        vertical-align: middle;
    }

    &:checked + div {
        background-color: #3b89ec;
        width: 48px;
    }

    + div {
        height: 16px;
        width: 48px;
    }

    &:checked + div {
        background-color: rgba(54, 172, 163, 0.3);
    }

    + div > div {
        background: #d3d8dd;
        border-radius: inherit;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
        height: 24px;
        margin-left: 1px;
        margin-top: 1px;
        pointer-events: none;
        position: relative;
        top: -3px;
        transition-duration: 0.3s;
        transition-property: transform, background-color;
        transition-timing-function: ease-in-out;
        width: 24px;
    }

    &:checked {

        + div > div {
            background-color: $color_primary;
            transform: translate3d(22px, 0, 0);
        }
    }
}

.settings {
    background-color: $body_bg_color;
    padding-top: 35px;

    .title {
        color: $darkest_text_color;
        font-weight: normal;
        margin-bottom: 14px;
    }

    .option-title {
        color: $light_text_color;
        font-weight: 600;
        margin-bottom: 4px;
    }

    &-page {
        background-color: #fff;
    }

    &-wrapper {

        > div > ul {
            box-shadow: none;
            margin: 0 auto;
            max-width: 1040px;
        }
    }

    &-container {
        margin: 0 auto;
        max-width: 1056px;
    }

    &-block {
        border: 1px solid #dfe3eb;
        padding: 16px;

        *:last-child {
            margin-bottom: 0;
        }

    }

    &-action {
        background-color: $body_bg_color;
        padding-top: 32px;
    }

    p {
        line-height: 1.2;
    }
}

.heading-container {
    margin: 2px auto 0;
    max-width: 1124px;

    p {
        margin-bottom: 0;
    }
}

.rules-block {
    background: rgba(245, 247, 250, 0.5);
    padding: 16px;

    p {
        margin-bottom: 0;
    }
}

.settings-list {
    border: 1px solid #dfe3eb;
}

@media (max-width: 480px) {

    .option-label {
        margin-right: 0;
    }
}
