.heading {
    padding: 8px 32px;

    &.center {
        text-align: center;

        .subtitle {
            margin-left: auto;
            margin-right: auto;
            max-width: 345px;
        }
    }

    &.left {
        text-align: left;
    }

    &.right {
        text-align: right;
    }
}

h1.title {
    line-height: 1.27;
    margin: 12px 0 0;
}

@media (max-width: 480px) {

    .heading {
        padding-bottom: 0;
    }
}
