@import "../../../assets/scss/variables";

.button {
    align-items: center;
    border-radius: $base_border_radius * 2;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    height: 48px;
    justify-content: center;
    line-height: 1;
    max-width: 350px;
    padding: 4px;
    position: relative;
    transition: all 0.2s linear;
    width: 100%;

    .icon-wrapper {
        align-items: center;
        background-color: #fff;
        border-radius: 4px;
        height: 40px;
        justify-content: center;
        left: 3px;
        position: absolute;
        top: 3px;
        width: 40px;
    }

    &.blue {
        background-color: #518ef8;
        border: 1px solid #518ef8;

        &:hover {
            background-color: #206ef6;
            border-color: #206ef6;
            color: #fff;
        }
    }

    &.red {
        background-color: #eb3b00;
        border: 1px solid #eb3b00;

        &:hover {
            background-color: #b82e00;
            border-color: #b82e00;
            color: #fff;
        }
    }

    &.black {
        background-color: #191717;
        border: 1px solid #eb3b00;
        border: 1px solid #191717;

        &:hover {
            background-color: #010000;
            border-color: #010000;
            color: #fff;
        }
    }

    &:hover {
        background-color: #fff;
        text-decoration: none;
    }
}
