@import "../../../assets/scss/variables";

.section-heading {
    margin-bottom: 13px;
}

.settings-link {
    color: $light_text_color;
    font-size: 16px;

    span {
        padding-left: 8px;
    }

    svg {
        height: 16px;
        width: 16px;

        path {
            fill: $light_text_color;
        }
    }

    &:hover {
        text-decoration: none;

        path {
            fill: $color_primary;
        }
    }
}

@media (max-width: 480px) {

    .settings-link {
        font-size: 14px;
    }

    .section-heading {
        margin-bottom: 6px;
    }
}
