body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
ul.header li {
  display: inline;
  list-style-type: none;
  margin: 0;
}
ul.header {
  background-color: #111;
  padding: 0;
}
ul.header li a {
  color: #FFF;
  font-weight: bold;
  text-decoration: none;
  padding: 20px;
  display: inline-block;
}
.content {
  background-color: #FFF;
  padding: 20px;
}
.content h2 {
  padding: 0;
  margin: 0;
}
.content li {
  margin-bottom: 10px;
}
.error-block {
  color: red;
}
.header {
  background: #4286f4;
}

.modal-window {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  z-index: 50;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.modal-window-container {
  z-index: 55;
  width: 300px;
  height: 200px;
  background: rgba(235, 235, 235, 1);
}

.App {
  /*text-align: center;*/
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 50vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#wpbody-content .metabox-holder{
  display: -webkit-inline-box;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* ==========================================================================
   Normalize.scss settings
   ========================================================================== */

/**
 * Includes legacy browser support IE6/7
 *
 * Set to false if you want to drop support for IE6 and IE7
 */

$legacy_browser_support: false !default;

/* Base
   ========================================================================== */

/**
 * 1. Set default font family to sans-serif.
 * 2. Prevent iOS and IE text size adjust after device orientation change,
 *    without disabling user zoom.
 * 3. Corrects text resizing oddly in IE 6/7 when body `font-size` is set using
 *  `em` units.
 */

html {
    font-family: sans-serif; /* 1 */
    -ms-text-size-adjust: 100%; /* 2 */
    -webkit-text-size-adjust: 100%; /* 2 */
    @if $legacy_browser_support {
        *font-size: 100%; /* 3 */
    }
}

/**
 * Remove default margin.
 */

body {
    margin: 0;
}

/* HTML5 display definitions
   ========================================================================== */

/**
 * Correct `block` display not defined for any HTML5 element in IE 8/9.
 * Correct `block` display not defined for `details` or `summary` in IE 10/11
 * and Firefox.
 * Correct `block` display not defined for `main` in IE 11.
 */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
    display: block;
}

/**
 * 1. Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
 */

audio,
canvas,
progress,
video {
    display: inline-block; /* 1 */
    vertical-align: baseline; /* 2 */
    @if $legacy_browser_support {
        *display: inline;
        *zoom: 1;
    }
}

/**
 * Prevents modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */

audio:not([controls]) {
    display: none;
    height: 0;
}

/**
 * Address `[hidden]` styling not present in IE 8/9/10.
 * Hide the `template` element in IE 8/9/10/11, Safari, and Firefox < 22.
 */

[hidden],
template {
    display: none;
}

/* Links
   ========================================================================== */

/**
 * Remove the gray background color from active links in IE 10.
 */

a {
    background-color: transparent;
}

/**
 * Improve readability of focused elements when they are also in an
 * active/hover state.
 */

a {

    &:active,
    &:hover {
        outline: 0;
    };
}

/* Text-level semantics
   ========================================================================== */

/**
 * Address styling not present in IE 8/9/10/11, Safari, and Chrome.
 */

abbr[title] {
    border-bottom: 1px dotted;
}

/**
 * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
 */

b,
strong {
    font-weight: bold;
}

@if $legacy_browser_support {

    blockquote {
        margin: 1em 40px;
    }
}

/**
 * Address styling not present in Safari and Chrome.
 */

dfn {
    font-style: italic;
}

/**
 * Address variable `h1` font-size and margin within `section` and `article`
 * contexts in Firefox 4+, Safari, and Chrome.
 */

h1 {
    font-size: 2em;
    margin: 0.67em 0;
}

@if $legacy_browser_support {

    h2 {
        font-size: 1.5em;
        margin: 0.83em 0;
    }

    h3 {
        font-size: 1.17em;
        margin: 1em 0;
    }

    h4 {
        font-size: 1em;
        margin: 1.33em 0;
    }

    h5 {
        font-size: 0.83em;
        margin: 1.67em 0;
    }

    h6 {
        font-size: 0.67em;
        margin: 2.33em 0;
    }
}

/**
 * Addresses styling not present in IE 8/9.
 */

mark {
    background: #ff0;
    color: #000;
}

@if $legacy_browser_support {
    /**
     * Addresses margins set differently in IE 6/7.
     */

    p,
    pre {
        *margin: 1em 0;
    }

    /*
     * Addresses CSS quotes not supported in IE 6/7.
     */

    q {
        *quotes: none;
    }

    /*
     * Addresses `quotes` property not supported in Safari 4.
     */

    q:before,
    q:after {
        content: "";
        content: none;
    }
}

/**
 * Address inconsistent and variable font size in all browsers.
 */

small {
    font-size: 80%;
}

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -0.5em;
}

sub {
    bottom: -0.25em;
}

@if $legacy_browser_support {
    /* ==========================================================================
       Lists
       ========================================================================== */

    /*
     * Addresses margins set differently in IE 6/7.
     */

    dl,
    menu,
    ol,
    ul {
        *margin: 1em 0;
    }

    dd {
        *margin: 0 0 0 40px;
    }

    /*
     * Addresses paddings set differently in IE 6/7.
     */

    menu,
    ol,
    ul {
        *padding: 0 0 0 40px;
    }

    /*
     * Corrects list images handled incorrectly in IE 7.
     */

    nav ul,
    nav ol {
        *list-style: none;
        *list-style-image: none;
    }

}

/* Embedded content
   ========================================================================== */

/**
 * 1. Remove border when inside `a` element in IE 8/9/10.
 * 2. Improves image quality when scaled in IE 7.
 */

img {
    border: 0;
    @if $legacy_browser_support {
        *-ms-interpolation-mode: bicubic; /* 2 */
    }
}

/**
 * Correct overflow not hidden in IE 9/10/11.
 */

svg:not(:root) {
    overflow: hidden;
}

/* Grouping content
   ========================================================================== */

/**
 * Address margin not present in IE 8/9 and Safari.
 */

figure {
    margin: 1em 40px;
}

/**
 * Address differences between Firefox and other browsers.
 */

hr {
    box-sizing: content-box;
    height: 0;
}

/**
 * Contain overflow in all browsers.
 */

pre {
    overflow: auto;
}

/**
 * Address odd `em`-unit font size rendering in all browsers.
 * Correct font family set oddly in IE 6, Safari 4/5, and Chrome.
 */

code,
kbd,
pre,
samp {
    font-family: monospace, monospace;
    font-size: 1em;
    @if $legacy_browser_support {
        _font-family: "courier new", monospace;
    }
}

/* Forms
   ========================================================================== */

/**
 * Known limitation: by default, Chrome and Safari on OS X allow very limited
 * styling of `select`, unless a `border` property is set.
 */

/**
 * 1. Correct color not being inherited.
 *  Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 * 4. Improves appearance and consistency in all browsers.
 */

button,
input,
optgroup,
select,
textarea {
    color: inherit; /* 1 */
    font: inherit; /* 2 */
    margin: 0; /* 3 */
    @if $legacy_browser_support {
        vertical-align: baseline; /* 3 */
        *vertical-align: middle; /* 3 */
    }
}

/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */

button {
    overflow: visible;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */

button,
select {
    text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *  and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *  `input` and others.
 * 4. Removes inner spacing in IE 7 without affecting normal text inputs.
 *  Known issue: inner spacing remains in IE 6.
 */

button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button; /* 2 */
    cursor: pointer; /* 3 */
    @if $legacy_browser_support {
        *overflow: visible;  /* 4 */
    }
}

/**
 * Re-set default cursor for disabled elements.
 */

button[disabled],
html input[disabled] {
    cursor: default;
}

/**
 * Remove inner padding and border in Firefox 4+.
 */

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */

input {
    line-height: normal;
}

/**
 * 1. Address box sizing set to `content-box` in IE 8/9/10.
 * 2. Remove excess padding in IE 8/9/10.
 *  Known issue: excess padding remains in IE 6.
 */

input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box; /* 1 */
    padding: 0; /* 2 */
    @if $legacy_browser_support {
        *height: 13px; /* 3 */
        *width: 13px; /* 3 */
    }
}

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari and Chrome.
 */

input[type="search"] {
    -webkit-appearance: textfield; /* 1 */
    box-sizing: content-box; /* 2 */
}

/**
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

/**
 * Define consistent border, margin, and padding.
 */

fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct `color` not being inherited in IE 8/9/10/11.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 * 3. Corrects text not wrapping in Firefox 3.
 * 4. Corrects alignment displayed oddly in IE 6/7.
 */

legend {
    border: 0; /* 1 */
    padding: 0; /* 2 */
    @if $legacy_browser_support {
        white-space: normal; /* 3 */
        *margin-left: -7px; /* 4 */
    }
}

/**
 * Remove default vertical scrollbar in IE 8/9/10/11.
 */

textarea {
    overflow: auto;
}

/**
 * Don't inherit the `font-weight` (applied by a rule above).
 * NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
 */

optgroup {
    font-weight: bold;
}

/* Tables
   ========================================================================== */

/**
 * Remove most spacing between table cells.
 */

table {
    border-collapse: collapse;
    border-spacing: 0;
}

td,
th {
    padding: 0;
}


.serviceWorker_modal-wrapper__3ICSs {
  -webkit-align-items: center;
          align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  display: -webkit-flex;
  display: flex;
  height: 100%;
  -webkit-justify-content: center;
          justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999; }

.serviceWorker_modal-container__1nry3 {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 7px 15px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-width: 320px;
  overflow: auto;
  padding: 20px;
  width: 30%;
  z-index: 10; }

.serviceWorker_get-started-link__Ak1E8 {
  -webkit-align-self: flex-start;
          align-self: flex-start;
  background-color: #36aca3;
  border: 1px solid #36aca3;
  border-radius: 6px;
  box-shadow: 0 3px 12px rgba(134, 160, 158, 0.15);
  color: #fff;
  padding: 5px 18px;
  text-align: center;
  transition: all 0.2s linear; }
  .serviceWorker_get-started-link__Ak1E8:hover {
    background-color: #28857f;
    border-color: #28857f;
    color: #fff;
    text-decoration: none; }

.global_list__2nJHe {
  list-style: none;
  margin: 0;
  padding-left: 0; }

h1.global_title__3WON1 {
  color: #1f3041;
  font-size: 28px;
  font-weight: 300;
  line-height: 1.5; }

h2.global_title__3WON1 {
  font-size: 24px;
  line-height: 1.2; }

h3.global_title__3WON1 {
  color: #637381;
  font-size: 20px;
  font-weight: 300;
  line-height: 1.5; }

h4.global_title__3WON1 {
  color: #32373c;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5; }

h5.global_title__3WON1 {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.625; }

p.global_paragraph__Bq0Yo {
  line-height: 1.5; }

.global_container__2ZvWe {
  max-width: 1300px; }

.global_section__1Y_hs {
  padding: 30px 0;
  position: relative; }
  .global_section-heading__1Ku3O {
    text-align: center; }
  .global_section-title__2RwzX {
    margin-bottom: 6px; }
  .global_section-subtitle__rXdGa {
    font-size: 14px;
    margin-bottom: 6px; }
  .global_section-body__2BAo4 {
    padding-top: 13px; }

.global_sign-up-form__2D-4N {
  border-radius: 6px;
  margin: 0 auto;
  max-width: 400px;
  padding: 32px 24px 24px; }

.global_d-flex__2kSuM {
  display: -webkit-flex;
  display: flex; }

.global_flex-column__3HG1e {
  -webkit-flex-direction: column;
          flex-direction: column; }

.global_justify-content-between__drbbq {
  -webkit-justify-content: space-between;
          justify-content: space-between; }

.global_justify-content-center__1eHQ7 {
  -webkit-justify-content: center;
          justify-content: center; }

.global_justify-content-end__U-0ta {
  -webkit-justify-content: flex-end;
          justify-content: flex-end; }

.global_align-items-center__7c551 {
  -webkit-align-items: center;
          align-items: center; }

.global_bg-white__h2PIp {
  background-color: #fff; }

.global_with-shadow__37vO3 {
  box-shadow: 0 3px 12px rgba(134, 160, 158, 0.15); }

.global_fixed__2_IeZ {
  max-width: inherit;
  position: fixed;
  width: inherit; }

.global_sign-up-form-wrapper__gLLhW {
  border-radius: 6px;
  margin: 0 auto;
  max-width: 400px;
  padding: 32px 24px 24px; }

.global_form-row__3ETBj {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-bottom: 16px; }

.global_list-pricing-plans__1Q1K4 .global_list-item__InzWD:first-child .global_card-subtitle__2NLWd, .global_list-pricing-plans__1Q1K4 .global_list-item__InzWD:last-child .global_card-subtitle__2NLWd {
  max-width: 190px; }

.global_statistic-list__3lc57 {
  padding: 21px 8px 16px; }

.global_statistic-item__3oA6W:not(:last-child) {
  border-right: 1px solid #ececec; }

.global_row__1QTsn {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin: 0 -15px; }

.global_row-with-5-col__9U7JS .global_col__1DAb- {
  padding: 0 15px;
  width: 20%; }

.global_col-sm-1__3DLFc,
.global_col-sm-2__1JWb4,
.global_col-sm-3__Vxc9t,
.global_col-sm-4__3ZDcO,
.global_col-sm-5__3fXJU,
.global_col-sm-6__fIlBV,
.global_col-sm-7__1d6xa,
.global_col-sm-8__kvVgQ,
.global_col-sm-9__31J9d,
.global_col-sm-10__1J95a,
.global_col-sm-11__1ot3N,
.global_col-sm-12__3-6gX,
.global_col-sm__30X7a,
.global_col-md-1__3FZk4,
.global_col-md-2__3wY_4,
.global_col-md-3__37sKV,
.global_col-md-4__25RYf,
.global_col-md-5__cADFu,
.global_col-md-6__1BnTP,
.global_col-md-7__skN5-,
.global_col-md-8__1dQSt,
.global_col-md-9___W_-Q,
.global_col-md-10__2Vfnk,
.global_col-md-11__38mG_,
.global_col-md-12__3MOoO,
.global_col-md__1DrYQ,
.global_col-lg-1__33PaG,
.global_col-lg-2__2fR17,
.global_col-lg-3__1GoP_,
.global_col-lg-4__23Hic,
.global_col-lg-5__14OFr,
.global_col-lg-6__2GP7P,
.global_col-lg-7__3hXTV,
.global_col-lg-8__2-Vzc,
.global_col-lg-9__3P50Y,
.global_col-lg-10__2gVW5,
.global_col-lg-11__MuDC6,
.global_col-lg-12__8Zpdp,
.global_col-lg__3VTLM,
.global_col-xl-1__3navm,
.global_col-xl-2__13Up6,
.global_col-xl-3__261oz,
.global_col-xl-4__fZRFm,
.global_col-xl-5__3xpxv,
.global_col-xl-6__26Hda,
.global_col-xl-7__3zPYF,
.global_col-xl-8__33_Qt,
.global_col-xl-9__341dF,
.global_col-xl-10__3PZ46,
.global_col-xl-11__2GRk_,
.global_col-xl-12__1UByz,
.global_col-xl__35mVV {
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  width: 100%; }

@media (min-width: 576px) {
  .global_col-sm-1__3DLFc {
    -webkit-flex: 0 0 8.333333%;
            flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .global_col-sm-2__1JWb4 {
    -webkit-flex: 0 0 16.666667%;
            flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .global_col-sm-3__Vxc9t {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .global_col-sm-4__3ZDcO {
    -webkit-flex: 0 0 33.333333%;
            flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .global_col-sm-5__3fXJU {
    -webkit-flex: 0 0 41.666667%;
            flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .global_col-sm-6__fIlBV {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .global_col-sm-7__1d6xa {
    -webkit-flex: 0 0 58.333333%;
            flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .global_col-sm-8__kvVgQ {
    -webkit-flex: 0 0 66.666667%;
            flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .global_col-sm-9__31J9d {
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .global_col-sm-10__1J95a {
    -webkit-flex: 0 0 83.333333%;
            flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .global_col-sm-11__1ot3N {
    -webkit-flex: 0 0 91.666667%;
            flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .global_col-sm-12__3-6gX {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 769px) {
  .global_col-md-1__3FZk4 {
    -webkit-flex: 0 0 8.333333%;
            flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .global_col-md-2__3wY_4 {
    -webkit-flex: 0 0 16.666667%;
            flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .global_col-md-3__37sKV {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .global_col-md-4__25RYf {
    -webkit-flex: 0 0 33.333333%;
            flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .global_col-md-5__cADFu {
    -webkit-flex: 0 0 41.666667%;
            flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .global_col-md-6__1BnTP {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .global_col-md-7__skN5- {
    -webkit-flex: 0 0 58.333333%;
            flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .global_col-md-8__1dQSt {
    -webkit-flex: 0 0 66.666667%;
            flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .global_col-md-9___W_-Q {
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .global_col-md-10__2Vfnk {
    -webkit-flex: 0 0 83.333333%;
            flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .global_col-md-11__38mG_ {
    -webkit-flex: 0 0 91.666667%;
            flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .global_col-md-12__3MOoO {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 992px) {
  .global_col-lg-1__33PaG {
    -webkit-flex: 0 0 8.333333%;
            flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .global_col-lg-2__2fR17 {
    -webkit-flex: 0 0 16.666667%;
            flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .global_col-lg-3__1GoP_ {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .global_col-lg-4__23Hic {
    -webkit-flex: 0 0 33.333333%;
            flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .global_col-lg-5__14OFr {
    -webkit-flex: 0 0 41.666667%;
            flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .global_col-lg-6__2GP7P {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .global_col-lg-7__3hXTV {
    -webkit-flex: 0 0 58.333333%;
            flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .global_col-lg-8__2-Vzc {
    -webkit-flex: 0 0 66.666667%;
            flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .global_col-lg-9__3P50Y {
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .global_col-lg-10__2gVW5 {
    -webkit-flex: 0 0 83.333333%;
            flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .global_col-lg-11__MuDC6 {
    -webkit-flex: 0 0 91.666667%;
            flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .global_col-lg-12__8Zpdp {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; } }

@media (max-width: 768px) {
  h1.global_title__3WON1 {
    font-size: 25px; }
  h2.global_title__3WON1 {
    font-size: 22px; }
  h3.global_title__3WON1 {
    font-size: 18px; }
  h4.global_title__3WON1 {
    font-size: 16px; }
  h5.global_title__3WON1 {
    font-size: 16px; }
  .global_row-with-5-col__9U7JS {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap; }
    .global_row-with-5-col__9U7JS .global_col__1DAb- {
      margin-bottom: 16px;
      width: 33.33%; }
      .global_row-with-5-col__9U7JS .global_col__1DAb-:nth-child(4) {
        margin-bottom: 0;
        width: 50%; }
      .global_row-with-5-col__9U7JS .global_col__1DAb-:nth-child(5) {
        margin-bottom: 0;
        width: 50%; }
  .global_col-md-3__37sKV.global_statistic-item__3oA6W {
    width: 50%; }
    .global_col-md-3__37sKV.global_statistic-item__3oA6W:first-child, .global_col-md-3__37sKV.global_statistic-item__3oA6W:nth-child(2) {
      border-bottom: 1px solid #ececec; } }

@media (max-width: 480px) {
  h1.global_title__3WON1 {
    font-size: 20px; }
  .global_section__1Y_hs {
    padding-top: 0; }
    .global_section-body__2BAo4 {
      padding-top: 0; }
  h3.global_title__3WON1 {
    font-size: 16px; }
  p {
    line-height: 1.2; } }

.navigation_nav-menu__1CVd5 a:not(.navigation_get-started-link__2hNh6) {
  color: #666;
  font-size: 14px; }

.navigation_nav-menu__1CVd5 .navigation_list-item__2yLHp:not(:last-child) {
  margin-right: 40px; }

.navigation_get-started-link__2hNh6 {
  background-color: #36aca3;
  border: 1px solid #36aca3;
  border-radius: 6px;
  box-shadow: 0 3px 12px rgba(134, 160, 158, 0.15);
  color: #fff;
  padding: 5px 18px;
  transition: all 0.2s linear; }
  .navigation_get-started-link__2hNh6:hover {
    background-color: #28857f;
    border-color: #28857f;
    color: #fff;
    text-decoration: none; }

@media (max-width: 920px) {
  .navigation_nav-menu__1CVd5 {
    background-color: #fff;
    box-shadow: 0 3px 12px rgba(134, 160, 158, 0.15);
    -webkit-flex-direction: column;
            flex-direction: column;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    max-width: 414px;
    padding: 0;
    position: absolute;
    right: -9px;
    top: 32px;
    width: 100vw;
    z-index: 9; }
    .navigation_nav-menu__1CVd5 .navigation_list-item__2yLHp {
      padding: 16px;
      width: 100%; }
      .navigation_nav-menu__1CVd5 .navigation_list-item__2yLHp:not(:last-child) {
        margin-right: 0; }
  .navigation_get-started-link__2hNh6 {
    background-color: #fff;
    border: 0;
    box-shadow: none;
    color: #36aca3;
    padding: 0;
    text-decoration: underline;
    white-space: nowrap; } }

.dropdown_dropdown-menu__1qF5F {
  display: none;
  max-width: 282px;
  position: absolute;
  right: 0;
  top: 48px;
  width: 100%;
  z-index: 99; }
  .dropdown_dropdown-menu__1qF5F.dropdown_opened__DQEu7 {
    display: block; }

.dropdown_dropdown-link__23Qsp {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: #fff;
  border: 0;
  color: #666;
  cursor: pointer;
  padding: 16px;
  text-align: left;
  width: 100%; }
  .dropdown_dropdown-link__23Qsp:hover {
    background-color: #f5f7fa; }
  .dropdown_dropdown-link__23Qsp:focus {
    outline: none; }

.dropdown_dropdown__2-1fl li {
  -webkit-flex-direction: column;
          flex-direction: column; }

.dropdown_toggle-btn__pcWkk {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: transparent;
  border: 0;
  color: #4f4f4f;
  font-weight: 500; }
  .dropdown_toggle-btn__pcWkk:focus {
    outline: none; }
  .dropdown_toggle-btn__pcWkk:hover {
    color: #637381; }
  .dropdown_toggle-btn__pcWkk img {
    border-radius: 50%;
    height: 16px;
    margin-right: 7px;
    object-fit: cover;
    width: 16px; }

.dropdown_badge__witZS {
  -webkit-align-items: center;
          align-items: center;
  background-color: #d16d4b;
  border-radius: 50%;
  color: #fff;
  display: -webkit-inline-flex;
  display: inline-flex;
  font-size: 12px;
  height: 16px;
  -webkit-justify-content: center;
          justify-content: center;
  line-height: 1;
  margin-left: 26px;
  width: 16px; }

.dropdown_logged-as__3_USN {
  display: none; }

@media (max-width: 920px) {
  .dropdown_logged-as__3_USN {
    display: -webkit-inline-flex;
    display: inline-flex;
    padding-right: 20px; }
  .dropdown_dropdown-wrapper__mI5Wt {
    background-color: #fff;
    box-shadow: 0 3px 12px rgba(134, 160, 158, 0.15);
    max-width: 414px;
    padding: 0;
    position: absolute;
    right: -9px;
    top: 32px;
    width: 100vw;
    z-index: 9; }
  .dropdown_dropdown-menu__1qF5F {
    box-shadow: none;
    display: block;
    position: static; }
  .dropdown_toggle-btn__pcWkk {
    color: #666;
    font-weight: 400;
    padding: 16px; }
  .dropdown_upgrade-link__1IKdg {
    color: #36aca3; } }

.topPanel_panel__1GbMt {
  max-width: inherit;
  padding: 10px;
  position: relative;
  width: inherit;
  z-index: 99; }
  .topPanel_panel-left__h0Izi {
    -webkit-align-items: center;
            align-items: center; }
  .topPanel_panel-right__3gUEy p {
    color: #666;
    font-size: 16px;
    margin-bottom: 0;
    margin-top: 0; }
  .topPanel_panel-title__2hibb {
    -webkit-align-items: center;
            align-items: center;
    color: #165362;
    margin-bottom: 0;
    margin-left: 12px;
    margin-top: 0; }
    .topPanel_panel-title__2hibb:before {
      background-color: #36aca3;
      content: "";
      display: -webkit-inline-flex;
      display: inline-flex;
      height: 16px;
      margin-right: 12px;
      width: 2px; }
  .topPanel_panel-content__1q0Fb {
    margin: 0 auto;
    max-width: 1280px;
    position: relative; }

.topPanel_toggle-nav__1PRDR {
  cursor: pointer;
  display: none;
  height: 16px;
  margin-left: auto;
  margin-right: 22px;
  position: relative;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  width: 19px;
  z-index: 99; }
  .topPanel_toggle-nav__1PRDR span {
    background: #36aca3;
    display: block;
    height: 2px;
    left: 0;
    opacity: 1;
    position: absolute;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    transition: 0.25s ease-in-out;
    width: 100%; }
    .topPanel_toggle-nav__1PRDR span:nth-child(1) {
      top: 0;
      -webkit-transform-origin: left center;
              transform-origin: left center; }
    .topPanel_toggle-nav__1PRDR span:nth-child(2) {
      top: 6px;
      -webkit-transform-origin: left center;
              transform-origin: left center; }
    .topPanel_toggle-nav__1PRDR span:nth-child(3) {
      top: 12px;
      -webkit-transform-origin: left center;
              transform-origin: left center; }
  .topPanel_toggle-nav__1PRDR.topPanel_active__xCaPY span:nth-child(1) {
    left: 8px;
    top: 0;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .topPanel_toggle-nav__1PRDR.topPanel_active__xCaPY span:nth-child(2) {
    opacity: 0;
    width: 0; }
  .topPanel_toggle-nav__1PRDR.topPanel_active__xCaPY span:nth-child(3) {
    left: 8px;
    top: 13px;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }

@media (max-width: 920px) {
  .topPanel_toggle-nav__1PRDR {
    display: block; }
  .topPanel_nav-wrapper__2W596 {
    -webkit-transform: translateX(500px);
            transform: translateX(500px);
    transition: all 400ms ease-in-out; }
    .topPanel_nav-wrapper__2W596.topPanel_open__h_lAm {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      transition: all 400ms ease-in-out; } }

@media (max-width: 480px) {
  .topPanel_panel__1GbMt {
    margin: 0 -10px;
    width: auto; } }

.pricingPlanCard_card__Dnupv {
  -webkit-align-items: center;
          align-items: center;
  border-radius: 6px;
  -webkit-flex-direction: column;
          flex-direction: column;
  max-width: 328px;
  min-height: 430px;
  padding: 32px;
  position: relative;
  text-align: center; }
  .pricingPlanCard_card-action__3GEec {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: auto;
    width: 100%; }
  .pricingPlanCard_card-icon__5lj-2 {
    -webkit-align-items: center;
            align-items: center;
    background-color: #d2f5fb;
    border-radius: 50%;
    height: 104px;
    -webkit-justify-content: center;
            justify-content: center;
    margin: 0 auto 24px;
    width: 103px; }
  .pricingPlanCard_card-title__U0x38 {
    margin-bottom: 19px;
    margin-top: 0; }
  .pricingPlanCard_card-subtitle__1D-sT {
    color: #979797; }
  .pricingPlanCard_card-body__WxcyG {
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 1;
            flex: 1 1;
    -webkit-flex-direction: column;
            flex-direction: column; }

.pricingPlanCard_icon-checked__1H53Q {
  -webkit-align-items: center;
          align-items: center;
  background-color: #d8faea;
  border-radius: 50%;
  height: 20px;
  -webkit-justify-content: center;
          justify-content: center;
  left: 19px;
  position: absolute;
  top: 19px;
  width: 20px; }
  .pricingPlanCard_icon-checked__1H53Q svg {
    height: 10px;
    width: 13px; }

@media (max-width: 768px) {
  .pricingPlanCard_card__Dnupv {
    margin: 0 auto 25px; } }

@media (max-width: 480px) {
  .pricingPlanCard_card__Dnupv {
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-flex-direction: row;
            flex-direction: row;
    margin-bottom: 12px;
    max-width: 398px;
    min-height: unset;
    padding: 16px 24px 24px;
    width: 95%; }
    .pricingPlanCard_card-body__WxcyG {
      padding-left: 22px;
      text-align: left; }
    .pricingPlanCard_card-action__3GEec {
      -webkit-justify-content: flex-start;
              justify-content: flex-start; }
    .pricingPlanCard_card__Dnupv .pricingPlanCard_card-title__U0x38 {
      font-size: 18px;
      margin-bottom: 8px; }
    .pricingPlanCard_card__Dnupv .pricingPlanCard_card-subtitle__1D-sT {
      font-size: 16px; } }

.buttonPrimary_button__Pnh8w {
  -webkit-align-items: center;
          align-items: center;
  background-color: #36aca3;
  border: 1px solid #36aca3;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  -webkit-justify-content: center;
          justify-content: center;
  line-height: 1;
  padding: 16px;
  transition: all 0.2s linear;
  width: 100%; }
  .buttonPrimary_button__Pnh8w:hover {
    background-color: #28857f;
    border-color: #28857f;
    color: #fff; }
  .buttonPrimary_button__Pnh8w.buttonPrimary_btn-center__3f1-i {
    margin-left: auto;
    margin-right: auto; }
  .buttonPrimary_button__Pnh8w.buttonPrimary_small__27Eo_ {
    max-width: 159px; }
  .buttonPrimary_button__Pnh8w.buttonPrimary_medium__3L2Tp {
    max-width: 176px; }
  .buttonPrimary_button__Pnh8w.buttonPrimary_large__3MMZo {
    max-width: 200px; }
  .buttonPrimary_button__Pnh8w.buttonPrimary_with-icon__1Jqct {
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly; }
    .buttonPrimary_button__Pnh8w.buttonPrimary_with-icon__1Jqct:hover svg path {
      fill: #fff; }
    .buttonPrimary_button__Pnh8w.buttonPrimary_with-icon__1Jqct svg {
      margin-right: 14%; }
  .buttonPrimary_button__Pnh8w:focus {
    outline: none; }
  .buttonPrimary_button__Pnh8w:disabled {
    background-color: transparent;
    color: #36aca3; }

.logo_logo__2m3cc {
  -webkit-appearance: 0;
     -moz-appearance: 0;
          appearance: 0;
  background-color: transparent;
  border: 0; }
  .logo_logo__2m3cc:focus {
    outline: none; }

.buttonSignUp_button__TsGJQ {
  -webkit-align-items: center;
          align-items: center;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  height: 48px;
  -webkit-justify-content: center;
          justify-content: center;
  line-height: 1;
  max-width: 350px;
  padding: 4px;
  position: relative;
  transition: all 0.2s linear;
  width: 100%; }
  .buttonSignUp_button__TsGJQ .buttonSignUp_icon-wrapper__3wPVG {
    -webkit-align-items: center;
            align-items: center;
    background-color: #fff;
    border-radius: 4px;
    height: 40px;
    -webkit-justify-content: center;
            justify-content: center;
    left: 3px;
    position: absolute;
    top: 3px;
    width: 40px; }
  .buttonSignUp_button__TsGJQ.buttonSignUp_blue__2Anif {
    background-color: #518ef8;
    border: 1px solid #518ef8; }
    .buttonSignUp_button__TsGJQ.buttonSignUp_blue__2Anif:hover {
      background-color: #206ef6;
      border-color: #206ef6;
      color: #fff; }
  .buttonSignUp_button__TsGJQ.buttonSignUp_red__2upDA {
    background-color: #eb3b00;
    border: 1px solid #eb3b00; }
    .buttonSignUp_button__TsGJQ.buttonSignUp_red__2upDA:hover {
      background-color: #b82e00;
      border-color: #b82e00;
      color: #fff; }
  .buttonSignUp_button__TsGJQ.buttonSignUp_black__2wf5V {
    background-color: #191717;
    border: 1px solid #eb3b00;
    border: 1px solid #191717; }
    .buttonSignUp_button__TsGJQ.buttonSignUp_black__2wf5V:hover {
      background-color: #010000;
      border-color: #010000;
      color: #fff; }
  .buttonSignUp_button__TsGJQ:hover {
    background-color: #fff;
    text-decoration: none; }

.input_input__Ere8g {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: #fff;
  border: 1px solid #8996a2;
  border-radius: 6px;
  color: #637381;
  font-size: 14px;
  font-weight: normal;
  height: 48px;
  padding: 8px 16px; }
  .input_input__Ere8g:-webkit-autofill, .input_input__Ere8g:-webkit-autofill:hover, .input_input-webkit-autofill__1HtTr:focus, .input_input-webkit-autofill__1HtTr, .input_input-webkit-autofill__1HtTr:hover, .input_input-webkit-autofill__1HtTr:focus, .input_input-webkit-autofill__1HtTr, .input_input-webkit-autofill__1HtTr:hover, .input_input-webkit-autofill__1HtTr:focus {
    border: 1px solid #8996a2;
    transition: background-color 5000s ease-in-out 0s; }
  .input_input__Ere8g:focus {
    border-color: #36aca3; }

.radioButton_container__2jP4u {
  border: 1px solid #8996a2;
  border-radius: 6px;
  color: #637381;
  cursor: pointer;
  display: block;
  font-size: 14px;
  font-weight: normal;
  height: 48px;
  margin-bottom: 0;
  padding: 14px 35px 8px 16px;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .radioButton_container__2jP4u:hover {
    border-color: #36aca3; }
  .radioButton_container__2jP4u.radioButton_active__39_3B {
    border: 2px solid #36aca3;
    color: #36aca3; }

.radioButton_container__2jP4u input {
  cursor: pointer;
  opacity: 0;
  position: absolute; }

.radioButton_checkmark__I5lJc {
  border: 1px solid #637381;
  border-radius: 50%;
  height: 20px;
  position: absolute;
  right: 15px;
  top: 13px;
  width: 20px; }

.radioButton_container__2jP4u input:checked ~ .radioButton_checkmark__I5lJc {
  background-color: #36aca3;
  border: 0; }

.radioButton_checkmark__I5lJc:after {
  content: "";
  display: none;
  position: absolute; }

.radioButton_container__2jP4u input:checked ~ .radioButton_checkmark__I5lJc:after {
  display: block; }

.radioButton_container__2jP4u .radioButton_checkmark__I5lJc:after {
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.07573 8.7728L0.175729 4.8728C-0.0585762 4.63849 -0.0585762 4.25859 0.175729 4.02426L1.02424 3.17573C1.25854 2.9414 1.63846 2.9414 1.87277 3.17573L4.5 5.80294L10.1272 0.175729C10.3615 -0.0585762 10.7415 -0.0585762 10.9758 0.175729L11.8243 1.02426C12.0586 1.25857 12.0586 1.63846 11.8243 1.87279L4.92426 8.77282C4.68994 9.00713 4.31004 9.00713 4.07573 8.7728Z' fill='white'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 50%;
  height: 10px;
  left: 5px;
  top: 6px;
  width: 12px; }

.buttonOutline_button__dXFr8 {
  -webkit-align-items: center;
          align-items: center;
  background-color: transparent;
  border: 1px solid #36aca3;
  border-radius: 6px;
  color: #36aca3;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  -webkit-justify-content: center;
          justify-content: center;
  line-height: 1;
  max-width: 351px;
  padding: 16px;
  transition: all 0.2s linear;
  width: 100%; }
  .buttonOutline_button__dXFr8:hover {
    background-color: #36aca3;
    color: #fff; }
  .buttonOutline_button__dXFr8:focus {
    outline: none; }

.input_input__2WuzS {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: #fff;
  border: 1px solid #8996a2;
  border-radius: 6px;
  color: #637381;
  font-size: 14px;
  font-weight: normal;
  height: 48px;
  padding: 8px 16px; }
  .input_input__2WuzS:-webkit-autofill, .input_input__2WuzS:-webkit-autofill:hover, .input_input-webkit-autofill__2HzY6:focus, .input_input-webkit-autofill__2HzY6, .input_input-webkit-autofill__2HzY6:hover, .input_input-webkit-autofill__2HzY6:focus, .input_input-webkit-autofill__2HzY6, .input_input-webkit-autofill__2HzY6:hover, .input_input-webkit-autofill__2HzY6:focus {
    border: 1px solid #8996a2;
    transition: background-color 5000s ease-in-out 0s; }
  .input_input-webkit-input-placeholder__1QA6K {
    color: rgba(99, 115, 129, 0.75); }
  .input_input-moz-placeholder__ysVPv {
    color: rgba(99, 115, 129, 0.75); }
  .input_input-ms-input-placeholder__2s8Fx {
    color: rgba(99, 115, 129, 0.75); }
  .input_input-moz-placeholder__ysVPv {
    color: rgba(99, 115, 129, 0.75); }
  .input_input__2WuzS:focus {
    border-color: #36aca3; }

.input_input-error__LVsIw {
  border-color: #dc3824;
  color: #dc3824; }

.input_error-block__vPW1J {
  color: #dc3824;
  font-size: 12px;
  font-weight: normal;
  line-height: 18px; }

.select_error-block__88ZCt {
  color: #dc3824;
  font-size: 12px;
  font-weight: normal;
  line-height: 18px; }

.tabs_tabs__g5bDV {
  box-shadow: none;
  cursor: pointer;
  margin: 0 auto;
  max-width: 1040px;
  position: relative;
  width: 100%; }
  .tabs_tabs__g5bDV > li {
    border-bottom: 4px solid transparent;
    color: #1f3041;
    padding: 16px 3.31% 8px; }
    .tabs_tabs__g5bDV > li.tabs_selected__7HYxa {
      border-bottom-color: #36aca3;
      color: #36aca3; }

@media (max-width: 768px) {
  .tabs_tabs__g5bDV li {
    font-size: 12px; } }

@media (max-width: 480px) {
  .tabs_tabs__g5bDV {
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin: 0 -10px;
    width: auto; }
    .tabs_tabs__g5bDV li {
      padding: 16px 8px 8px; } }

.label_label__6YiDU {
  border-radius: 20px;
  display: -webkit-inline-flex;
  display: inline-flex;
  font-size: 12px;
  height: 20px;
  padding: 1px 9px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }
  .label_label__6YiDU.label_warning__TlzVf {
    background-color: #fef8d8;
    color: #a97c21; }
  .label_label__6YiDU svg {
    margin-bottom: 2px;
    margin-right: 3px; }
  .label_label__6YiDU.label_active__2HWHI {
    background-color: #d8faea;
    color: #1a717b; }

.cardStatistic_card-heading__2AX1F {
  color: #1f3041;
  font-size: 28px;
  font-weight: 300;
  line-height: 1.27; }

.cardStatistic_card-subtitle__1KKxw {
  margin-bottom: 7px;
  text-align: center; }

@media (max-width: 480px) {
  .cardStatistic_card-heading__2AX1F {
    font-size: 24px;
    padding-bottom: 4px; }
  .cardStatistic_card-subtitle__1KKxw {
    line-height: 1.2;
    margin-bottom: 10px; }
  .cardStatistic_card__iD30H.cardStatistic_with-icon__qDrOz {
    -webkit-flex-direction: row;
            flex-direction: row;
    padding: 8px 6px 0; }
    .cardStatistic_card__iD30H.cardStatistic_with-icon__qDrOz .cardStatistic_card-heading__2AX1F svg {
      height: 24px;
      margin-right: 8px;
      width: 24px; }
    .cardStatistic_card__iD30H.cardStatistic_with-icon__qDrOz .cardStatistic_card-subtitle__1KKxw {
      margin-bottom: 0;
      padding-right: 18px; } }

.heading_heading__1OsbT {
  padding: 8px 32px; }
  .heading_heading__1OsbT.heading_center__ffuRZ {
    text-align: center; }
    .heading_heading__1OsbT.heading_center__ffuRZ .heading_subtitle__2DEsB {
      margin-left: auto;
      margin-right: auto;
      max-width: 345px; }
  .heading_heading__1OsbT.heading_left__3DVrZ {
    text-align: left; }
  .heading_heading__1OsbT.heading_right__38Ke- {
    text-align: right; }

h1.heading_title__37jWi {
  line-height: 1.27;
  margin: 12px 0 0; }

@media (max-width: 480px) {
  .heading_heading__1OsbT {
    padding-bottom: 0; } }

.sectionHeading_section-heading__zTpgW {
  margin-bottom: 13px; }

.sectionHeading_settings-link__1cIuZ {
  color: #637381;
  font-size: 16px; }
  .sectionHeading_settings-link__1cIuZ span {
    padding-left: 8px; }
  .sectionHeading_settings-link__1cIuZ svg {
    height: 16px;
    width: 16px; }
    .sectionHeading_settings-link__1cIuZ svg path {
      fill: #637381; }
  .sectionHeading_settings-link__1cIuZ:hover {
    text-decoration: none; }
    .sectionHeading_settings-link__1cIuZ:hover path {
      fill: #36aca3; }

@media (max-width: 480px) {
  .sectionHeading_settings-link__1cIuZ {
    font-size: 14px; }
  .sectionHeading_section-heading__zTpgW {
    margin-bottom: 6px; } }

.seeMore_see-more-wrapper__3Ofq1 {
  background: rgba(255, 255, 255, 0.8);
  bottom: 0;
  left: 0;
  padding: 11% 20px 20px;
  position: absolute;
  right: 0;
  top: 0; }

h3.dataIsLoading_title__29GaR {
  color: #36aca3; }

.dataIsLoading_loading-wrapper__1ugJs {
  background-color: rgba(255, 255, 255, 0.6);
  bottom: 0;
  -webkit-justify-content: center;
          justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0; }

@-webkit-keyframes dataIsLoading_rotating__TF44T {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes dataIsLoading_rotating__TF44T {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.dataIsLoading_rotating__TF44T {
  -webkit-animation: dataIsLoading_rotating__TF44T 2s linear infinite;
          animation: dataIsLoading_rotating__TF44T 2s linear infinite; }

.table_table__2QxIG {
  width: 100%; }
  .table_table__2QxIG th {
    color: #1f3041;
    font-weight: 500;
    padding: 21px 16px 12px; }
  .table_table__2QxIG td {
    color: #666;
    padding: 14px 16px; }
  .table_table__2QxIG th,
  .table_table__2QxIG td {
    border-bottom: 1px solid #d3d8dd; }
  .table_table__2QxIG th:last-child,
  .table_table__2QxIG td:last-child {
    text-align: right; }
  .table_table__2QxIG .table_align-center__283RC {
    text-align: center; }

.switchButton_switch-container__iEZfE {
  cursor: pointer;
  padding: 4px;
  position: relative; }

.switchButton_switch__4mzgv {
  opacity: 0;
  position: absolute; }
  .switchButton_switch__4mzgv + div {
    background-color: #f3f3f3;
    border-radius: 999px;
    cursor: pointer;
    transition-duration: 0.3s;
    transition-property: background-color, box-shadow;
    vertical-align: middle; }
  .switchButton_switch__4mzgv:checked + div {
    background-color: #3b89ec;
    width: 48px; }
  .switchButton_switch__4mzgv + div {
    height: 16px;
    width: 48px; }
  .switchButton_switch__4mzgv:checked + div {
    background-color: rgba(54, 172, 163, 0.3); }
  .switchButton_switch__4mzgv + div > div {
    background: #d3d8dd;
    border-radius: inherit;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
    height: 24px;
    margin-left: 1px;
    margin-top: 1px;
    pointer-events: none;
    position: relative;
    top: -3px;
    transition-duration: 0.3s;
    transition-property: background-color, -webkit-transform;
    transition-property: transform, background-color;
    transition-property: transform, background-color, -webkit-transform;
    transition-timing-function: ease-in-out;
    width: 24px; }
  .switchButton_switch__4mzgv:checked + div > div {
    background-color: #36aca3;
    -webkit-transform: translate3d(22px, 0, 0);
            transform: translate3d(22px, 0, 0); }

.checkbox_container__3oCRu {
  cursor: pointer;
  display: block;
  font-size: 22px;
  margin-bottom: 12px;
  padding-left: 32px;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .checkbox_container__3oCRu input {
    cursor: pointer;
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0; }

.checkbox_checkmark__2IVQu {
  background-color: transparent;
  border: 1px solid #8996a2;
  border-radius: 2px;
  height: 16px;
  left: 0;
  position: absolute;
  top: 0;
  width: 16px; }
  .checkbox_checkmark__2IVQu:after {
    content: "";
    display: none;
    position: absolute; }

.checkbox_container__3oCRu:hover input ~ .checkbox_checkmark__2IVQu {
  background-color: #f3f3f3; }

.checkbox_container__3oCRu input:checked ~ .checkbox_checkmark__2IVQu {
  background-color: #36aca3;
  border-color: #36aca3; }
  .checkbox_container__3oCRu input:checked ~ .checkbox_checkmark__2IVQu:after {
    display: block; }

.checkbox_container__3oCRu .checkbox_checkmark__2IVQu:after {
  background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.7153 1.79992L6.78464 0.866592C6.76916 0.85107 6.75076 0.838756 6.73052 0.830354C6.71027 0.821952 6.68856 0.817627 6.66664 0.817627C6.64471 0.817627 6.62301 0.821952 6.60276 0.830354C6.58251 0.838756 6.56412 0.85107 6.54864 0.866592L2.61797 4.79992C2.60249 4.81545 2.5841 4.82776 2.56385 4.83616C2.5436 4.84456 2.52189 4.84889 2.49997 4.84889C2.47805 4.84889 2.45634 4.84456 2.43609 4.83616C2.41584 4.82776 2.39745 4.81545 2.38197 4.79992L1.4513 3.86659C1.43582 3.85107 1.41743 3.83876 1.39718 3.83035C1.37693 3.82195 1.35523 3.81763 1.3333 3.81763C1.31138 3.81763 1.28967 3.82195 1.26943 3.83035C1.24918 3.83876 1.23079 3.85107 1.2153 3.86659L0.284638 4.79992C0.253392 4.83118 0.23584 4.87356 0.23584 4.91776C0.23584 4.96195 0.253392 5.00434 0.284638 5.03559L2.38197 7.13326C2.39745 7.14878 2.41584 7.16109 2.43609 7.1695C2.45634 7.1779 2.47805 7.18222 2.49997 7.18222C2.52189 7.18222 2.5436 7.1779 2.56385 7.1695C2.5841 7.16109 2.60249 7.14878 2.61797 7.13326L7.7153 2.03326C7.74584 2.0021 7.76295 1.96022 7.76295 1.91659C7.76295 1.87297 7.74584 1.83108 7.7153 1.79992Z' fill='white'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: contain;
  height: 9px;
  left: 3px;
  top: 3px;
  width: 9px; }


.select_select__2_yF3 {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
  background-image: linear-gradient(45deg, transparent 50%, #637381 50%), linear-gradient(135deg, #637381 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-repeat: no-repeat;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  border: 1px solid #8996a2;
  border-radius: 6px;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  min-width: 152px;
  padding: 8px 16px; }

.switch_switch-container__22BNE {
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 4px;
  position: relative; }

.switch_active__1aIOo {
  color: #36aca3; }

.switch_option-title__1FInJ {
  color: #637381;
  font-weight: 600;
  margin-bottom: 4px; }

.switch_option-label__2aZDx {
  margin-right: 16px; }

.switch_switch__eRZTo {
  opacity: 0;
  position: absolute; }
  .switch_switch__eRZTo + div {
    background-color: #f3f3f3;
    border-radius: 999px;
    cursor: pointer;
    transition-duration: 0.3s;
    transition-property: background-color, box-shadow;
    vertical-align: middle; }
  .switch_switch__eRZTo:checked + div {
    background-color: #3b89ec;
    width: 48px; }
  .switch_switch__eRZTo + div {
    height: 16px;
    width: 48px; }
  .switch_switch__eRZTo:checked + div {
    background-color: rgba(54, 172, 163, 0.3); }
  .switch_switch__eRZTo + div > div {
    background: #d3d8dd;
    border-radius: inherit;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
    height: 24px;
    margin-left: 1px;
    margin-top: 1px;
    pointer-events: none;
    position: relative;
    top: -3px;
    transition-duration: 0.3s;
    transition-property: background-color, -webkit-transform;
    transition-property: transform, background-color;
    transition-property: transform, background-color, -webkit-transform;
    transition-timing-function: ease-in-out;
    width: 24px; }
  .switch_switch__eRZTo:checked + div > div {
    background-color: #36aca3;
    -webkit-transform: translate3d(22px, 0, 0);
            transform: translate3d(22px, 0, 0); }

.switch_settings__2RIGi {
  background-color: #f5f7fa;
  padding-top: 35px; }
  .switch_settings__2RIGi .switch_title__L0bVW {
    color: #32373c;
    font-weight: normal;
    margin-bottom: 14px; }
  .switch_settings__2RIGi .switch_option-title__1FInJ {
    color: #637381;
    font-weight: 600;
    margin-bottom: 4px; }
  .switch_settings-page__dld7k {
    background-color: #fff; }
  .switch_settings-wrapper__2FCs9 > div > ul {
    box-shadow: none;
    margin: 0 auto;
    max-width: 1040px; }
  .switch_settings-container__VtHIz {
    margin: 0 auto;
    max-width: 1056px; }
  .switch_settings-block__1GZh3 {
    border: 1px solid #dfe3eb;
    padding: 16px; }
    .switch_settings-block__1GZh3 *:last-child {
      margin-bottom: 0; }
  .switch_settings-action__3PMNH {
    background-color: #f5f7fa;
    padding-top: 32px; }
  .switch_settings__2RIGi p {
    line-height: 1.2; }

.switch_heading-container__3PV2W {
  margin: 2px auto 0;
  max-width: 1124px; }
  .switch_heading-container__3PV2W p {
    margin-bottom: 0; }

.switch_rules-block__Ig87r {
  background: rgba(245, 247, 250, 0.5);
  padding: 16px; }
  .switch_rules-block__Ig87r p {
    margin-bottom: 0; }

.switch_settings-list__3KFSf {
  border: 1px solid #dfe3eb; }

@media (max-width: 480px) {
  .switch_option-label__2aZDx {
    margin-right: 0; } }

.checkbox_container__1uTps {
  cursor: pointer;
  display: block;
  font-size: 22px;
  margin-bottom: 12px;
  padding-left: 32px;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .checkbox_container__1uTps input {
    cursor: pointer;
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0; }

.checkbox_checkmark__3JbGY {
  background-color: transparent;
  border: 1px solid #8996a2;
  border-radius: 2px;
  height: 16px;
  left: 0;
  position: absolute;
  top: 0;
  width: 16px; }
  .checkbox_checkmark__3JbGY:after {
    content: "";
    display: none;
    position: absolute; }

.checkbox_container__1uTps:hover input ~ .checkbox_checkmark__3JbGY {
  background-color: #f3f3f3; }

.checkbox_container__1uTps input:checked ~ .checkbox_checkmark__3JbGY {
  background-color: #36aca3;
  border-color: #36aca3; }
  .checkbox_container__1uTps input:checked ~ .checkbox_checkmark__3JbGY:after {
    display: block; }

.checkbox_container__1uTps .checkbox_checkmark__3JbGY:after {
  background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.7153 1.79992L6.78464 0.866592C6.76916 0.85107 6.75076 0.838756 6.73052 0.830354C6.71027 0.821952 6.68856 0.817627 6.66664 0.817627C6.64471 0.817627 6.62301 0.821952 6.60276 0.830354C6.58251 0.838756 6.56412 0.85107 6.54864 0.866592L2.61797 4.79992C2.60249 4.81545 2.5841 4.82776 2.56385 4.83616C2.5436 4.84456 2.52189 4.84889 2.49997 4.84889C2.47805 4.84889 2.45634 4.84456 2.43609 4.83616C2.41584 4.82776 2.39745 4.81545 2.38197 4.79992L1.4513 3.86659C1.43582 3.85107 1.41743 3.83876 1.39718 3.83035C1.37693 3.82195 1.35523 3.81763 1.3333 3.81763C1.31138 3.81763 1.28967 3.82195 1.26943 3.83035C1.24918 3.83876 1.23079 3.85107 1.2153 3.86659L0.284638 4.79992C0.253392 4.83118 0.23584 4.87356 0.23584 4.91776C0.23584 4.96195 0.253392 5.00434 0.284638 5.03559L2.38197 7.13326C2.39745 7.14878 2.41584 7.16109 2.43609 7.1695C2.45634 7.1779 2.47805 7.18222 2.49997 7.18222C2.52189 7.18222 2.5436 7.1779 2.56385 7.1695C2.5841 7.16109 2.60249 7.14878 2.61797 7.13326L7.7153 2.03326C7.74584 2.0021 7.76295 1.96022 7.76295 1.91659C7.76295 1.87297 7.74584 1.83108 7.7153 1.79992Z' fill='white'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: contain;
  height: 9px;
  left: 3px;
  top: 3px;
  width: 9px; }

.checkbox_input-error__3DsA2 {
  border-color: #dc3824;
  color: #dc3824; }

.checkbox_error-block__3mU2- {
  color: #dc3824;
  font-size: 12px;
  font-weight: normal;
  line-height: 18px; }

.table_table__2mFmr {
  width: 100%; }
  .table_table__2mFmr th {
    color: #1f3041;
    font-weight: 500;
    padding: 21px 16px 12px; }
  .table_table__2mFmr td {
    color: #666;
    padding: 14px 16px; }
  .table_table__2mFmr th,
  .table_table__2mFmr td {
    border-bottom: 1px solid #d3d8dd; }
  .table_table__2mFmr th:last-child,
  .table_table__2mFmr td:last-child {
    text-align: right; }
  .table_table__2mFmr .table_align-center__vAA5f {
    text-align: center; }

.table_table__2ah-P {
  width: 100%; }
  .table_table__2ah-P th {
    color: #1f3041;
    font-weight: 500;
    padding: 21px 16px 12px; }
  .table_table__2ah-P td {
    color: #666;
    padding: 14px 16px; }
  .table_table__2ah-P th,
  .table_table__2ah-P td {
    border-bottom: 1px solid #d3d8dd; }
  .table_table__2ah-P th:last-child,
  .table_table__2ah-P td:last-child {
    text-align: right; }
  .table_table__2ah-P .table_align-center__2frc7 {
    text-align: center; }

.anchorBlock_anchor-block__1s916 {
  background-color: #fff;
  margin: -1px auto 0;
  max-width: 1280px;
  position: relative;
  width: 100%; }
  .anchorBlock_anchor-block__1s916 > li {
    border-bottom: 4px solid transparent;
    color: #1f3041;
    padding: 16px 3.31% 8px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; }
    .anchorBlock_anchor-block__1s916 > li button {
      -webkit-appearance: none;
         -moz-appearance: none;
              appearance: none;
      background-color: #fff;
      border: 0;
      color: #1f3041; }
      .anchorBlock_anchor-block__1s916 > li button:focus {
        outline: none; }
    .anchorBlock_anchor-block__1s916 > li.anchorBlock_selected__1_R9D {
      border-bottom-color: #36aca3; }
      .anchorBlock_anchor-block__1s916 > li.anchorBlock_selected__1_R9D button {
        color: #36aca3;
        text-decoration: none; }

@media (max-width: 920px) {
  .anchorBlock_anchor-block__1s916 {
    display: none; } }

h3.loaderElement_title__Uoez9 {
  color: #36aca3; }

.loaderElement_loading-wrapper__cNIgo {
  bottom: 0;
  -webkit-justify-content: center;
          justify-content: center;
  left: 0;
  padding-bottom: 20px;
  padding-top: 25px;
  right: 0;
  top: 0; }

@-webkit-keyframes loaderElement_rotating__2W8iB {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes loaderElement_rotating__2W8iB {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.loaderElement_rotating__2W8iB {
  -webkit-animation: loaderElement_rotating__2W8iB 2s linear infinite;
          animation: loaderElement_rotating__2W8iB 2s linear infinite; }

.chartDropdown_title__32Foy {
  font-weight: 500;
  padding-left: 10px; }
  .chartDropdown_title__32Foy .chartDropdown_arrow-icon__3612W {
    border-color: #637381 transparent transparent;
    border-style: solid;
    border-width: 7px 7px 0;
    display: inline-block;
    height: 0;
    margin-left: 4px;
    width: 0; }
  .chartDropdown_title__32Foy.chartDropdown_opened__3VCXH .chartDropdown_arrow-icon__3612W {
    margin-bottom: 2px;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }

.chartDropdown_chart-dropdown__2NhCW {
  font-size: 16px;
  line-height: 24px;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }
  .chartDropdown_chart-dropdown__2NhCW .chartDropdown_list__1BfO_ {
    border: 1px solid #ececec;
    position: absolute;
    right: 0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; }
  .chartDropdown_chart-dropdown__2NhCW .chartDropdown_list-item__1uW2G {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    background-color: #fff;
    border: 0;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    font-size: 14px;
    padding: 16px;
    white-space: nowrap;
    width: 100%; }
    .chartDropdown_chart-dropdown__2NhCW .chartDropdown_list-item__1uW2G:focus {
      outline: none; }
    .chartDropdown_chart-dropdown__2NhCW .chartDropdown_list-item__1uW2G:hover {
      background-color: #f2f9f7;
      color: #36aca3; }

.chartLegend_chart-legend__3bu4a {
  color: #666; }

.chartLegend_legend-dot__1b3xW {
  background-color: #637381;
  border-radius: 50%;
  display: inline-block;
  height: 6px;
  margin-right: 8px;
  width: 6px; }

.chartLegend_active__3XT4S .chartLegend_legend-dot__1b3xW {
  background-color: #36aca3; }

.toastMessage_toast-message-wrapper__1j1fY {
  background-color: rgba(220, 56, 36, 0.81);
  border-radius: 4px;
  color: #fff;
  font-weight: 500;
  padding: 16px;
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 999; }
  .toastMessage_toast-message-wrapper__1j1fY .toastMessage_message__LjFmu {
    margin-bottom: 0;
    padding-left: 10px; }
  .toastMessage_toast-message-wrapper__1j1fY svg {
    height: 22px;
    width: 17px; }
    .toastMessage_toast-message-wrapper__1j1fY svg path {
      fill: #fff; }
  .toastMessage_toast-message-wrapper__1j1fY.toastMessage_success__2nOva {
    background-color: rgba(54, 172, 163, 0.81); }
  .toastMessage_toast-message-wrapper__1j1fY.toastMessage_warning__3IFFx {
    background-color: rgba(246, 168, 33, 0.81); }
  .toastMessage_toast-message-wrapper__1j1fY.toastMessage_error__1J_PG {
    background-color: rgba(220, 56, 36, 0.81); }

.radiobutton_container__l-IWk {
  cursor: pointer;
  display: block;
  font-size: 22px;
  margin-bottom: 12px;
  padding-left: 32px;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .radiobutton_container__l-IWk input {
    cursor: pointer;
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0; }

.radiobutton_checkmark__2g1wA {
  background-color: transparent;
  border: 1px solid #8996a2;
  border-radius: 50%;
  height: 16px;
  left: 0;
  position: absolute;
  top: 0;
  width: 16px; }
  .radiobutton_checkmark__2g1wA:after {
    content: "";
    display: none;
    position: absolute; }

.radiobutton_container__l-IWk:hover input ~ .radiobutton_checkmark__2g1wA {
  background-color: #f3f3f3; }

.radiobutton_container__l-IWk input:checked ~ .radiobutton_checkmark__2g1wA {
  background-color: #36aca3;
  border-color: #36aca3; }
  .radiobutton_container__l-IWk input:checked ~ .radiobutton_checkmark__2g1wA:after {
    display: block; }

.radiobutton_input-error__Px4RT {
  border-color: #dc3824;
  color: #dc3824; }

.radiobutton_error-block__3y42W {
  color: #dc3824;
  font-size: 12px;
  font-weight: normal;
  line-height: 18px; }

.SignUp_label__1geHv {
  color: #1f3041;
  font-size: 12px;
  line-height: 1.4;
  margin-bottom: 8px; }

.SignUp_form-input-group__2lRsw {
  padding-top: 48px; }

.SignUp_social-btn-group__QE0Rw {
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 24px; }
  .SignUp_social-btn-group__QE0Rw .SignUp_form-row__o3hBX:last-child {
    margin-bottom: 0; }

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  src: url(https://web.auctollo-prod.pg-dev.com/static/media/Roboto-Regular.b9077621.eot);
  src: url(https://web.auctollo-prod.pg-dev.com/static/media/Roboto-Regular.b9077621.eot?#iefix) format("embedded-opentype"), url(https://web.auctollo-prod.pg-dev.com/static/media/Roboto-Regular.9feb0110.woff2) format("woff2"), url(https://web.auctollo-prod.pg-dev.com/static/media/Roboto-Regular.94dac78e.woff) format("woff"), url(https://web.auctollo-prod.pg-dev.com/static/media/Roboto-Regular.4312f1fb.ttf) format("truetype"); }

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  src: url(https://web.auctollo-prod.pg-dev.com/static/media/Roboto-Medium.02aa6e7b.eot);
  src: url(https://web.auctollo-prod.pg-dev.com/static/media/Roboto-Medium.02aa6e7b.eot?#iefix) format("embedded-opentype"), url(https://web.auctollo-prod.pg-dev.com/static/media/Roboto-Medium.50d01d3e.woff2) format("woff2"), url(https://web.auctollo-prod.pg-dev.com/static/media/Roboto-Medium.9a3bf7ac.woff) format("woff"), url(https://web.auctollo-prod.pg-dev.com/static/media/Roboto-Medium.b2bcaa52.ttf) format("truetype"); }

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  src: url(https://web.auctollo-prod.pg-dev.com/static/media/Poppins-Regular.e54f6a91.eot);
  src: url(https://web.auctollo-prod.pg-dev.com/static/media/Poppins-Regular.e54f6a91.eot?#iefix) format("embedded-opentype"), url(https://web.auctollo-prod.pg-dev.com/static/media/Poppins-Regular.e02e1902.woff2) format("woff2"), url(https://web.auctollo-prod.pg-dev.com/static/media/Poppins-Regular.1ed9a698.woff) format("woff"), url(https://web.auctollo-prod.pg-dev.com/static/media/Poppins-Regular.b400e683.ttf) format("truetype"); }

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  src: url(https://web.auctollo-prod.pg-dev.com/static/media/Poppins-Medium.dedb4980.eot);
  src: url(https://web.auctollo-prod.pg-dev.com/static/media/Poppins-Medium.dedb4980.eot?#iefix) format("embedded-opentype"), url(https://web.auctollo-prod.pg-dev.com/static/media/Poppins-Medium.2d9db5c9.woff2) format("woff2"), url(https://web.auctollo-prod.pg-dev.com/static/media/Poppins-Medium.896fb06c.woff) format("woff"), url(https://web.auctollo-prod.pg-dev.com/static/media/Poppins-Medium.322761ea.ttf) format("truetype"); }

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  src: url(https://web.auctollo-prod.pg-dev.com/static/media/Poppins-Light.af7b7661.eot);
  src: url(https://web.auctollo-prod.pg-dev.com/static/media/Poppins-Light.af7b7661.eot?#iefix) format("embedded-opentype"), url(https://web.auctollo-prod.pg-dev.com/static/media/Poppins-Light.3a7edf2f.woff2) format("woff2"), url(https://web.auctollo-prod.pg-dev.com/static/media/Poppins-Light.ceaf8c4d.woff) format("woff"), url(https://web.auctollo-prod.pg-dev.com/static/media/Poppins-Light.a67ce206.ttf) format("truetype"); }

* {
  box-sizing: border-box;
  scroll-behavior: smooth; }
  *:focus {
    outline: none; }

body {
  background-color: #f5f7fa;
  color: #637381;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5; }

.list {
  list-style: none;
  margin: 0;
  padding-left: 0; }

h1 {
  color: #1f3041;
  font-size: 28px;
  font-weight: 300;
  line-height: 1.5; }

h2 {
  font-size: 24px;
  line-height: 1.2; }

h3 {
  font-size: 20px;
  line-height: 1.5; }

h4 {
  color: #32373c;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5; }

h5 {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.625; }

p {
  font-size: 14px;
  line-height: 1.5; }

img {
  height: auto;
  max-width: 100%;
  object-fit: contain; }

a {
  color: #36aca3;
  text-decoration: none; }
  a:hover {
    color: #36aca3;
    text-decoration: underline; }

.container {
  margin: 0 auto;
  max-width: unset;
  overflow: hidden;
  padding: 0 20px;
  width: 100%; }

.fixed {
  background-color: #fff;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
  position: fixed;
  right: 0;
  width: inherit;
  z-index: 9; }

.App {
  margin: 0 auto;
  width: 100%; }

.welcome-section-wrapper {
  margin: 0 auto;
  max-width: 1280px;
  padding-top: 99px; }

.selectReg .react-select__control {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: #fff;
  background-image: linear-gradient(45deg, transparent 50%, #637381 50%), linear-gradient(135deg, #637381 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 8px), calc(100% - 15px) calc(1em + 8px), calc(100% - 2.5em) 0.5em;
  background-repeat: no-repeat;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  border: 1px solid #8996a2 !important;
  border-radius: 6px !important;
  color: #637381;
  font-size: 14px;
  font-weight: normal;
  height: 48px;
  padding: 8px 16px; }
  .selectReg .react-select__control:-webkit-autofill, .selectReg .react-select__control:-webkit-autofill:hover, .selectReg .react-select__control-webkit-autofill:focus, .selectReg .react-select__control-webkit-autofill, .selectReg .react-select__control-webkit-autofill:hover, .selectReg .react-select__control-webkit-autofill:focus, .selectReg .react-select__control-webkit-autofill, .selectReg .react-select__control-webkit-autofill:hover, .selectReg .react-select__control-webkit-autofill:focus {
    border: 1px solid #8996a2 !important;
    transition: background-color 5000s ease-in-out 0s !important; }
  .selectReg .react-select__control:focus {
    border-color: #36aca3; }

.selectReg .react-select__control--is-focused {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: #fff !important;
  background-image: linear-gradient(45deg, transparent 50%, #637381 50%), linear-gradient(135deg, #637381 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 8px), calc(100% - 15px) calc(1em + 8px), calc(100% - 2.5em) 0.5em;
  background-repeat: no-repeat;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  border: 1px solid #8996a2 !important;
  border-radius: 6px !important;
  box-shadow: none !important;
  color: #637381 !important;
  font-size: 14px;
  font-weight: normal;
  height: 48px;
  padding: 8px 16px; }
  .selectReg .react-select__control--is-focused:-webkit-autofill, .selectReg .react-select__control--is-focused:-webkit-autofill:hover, .selectReg .react-select__control--is-focused-webkit-autofill:focus, .selectReg .react-select__control--is-focused-webkit-autofill, .selectReg .react-select__control--is-focused-webkit-autofill:hover, .selectReg .react-select__control--is-focused-webkit-autofill:focus, .selectReg .react-select__control--is-focused-webkit-autofill, .selectReg .react-select__control--is-focused-webkit-autofill:hover, .selectReg .react-select__control--is-focused-webkit-autofill:focus {
    border: 1px solid #8996a2 !important;
    transition: background-color 5000s ease-in-out 0s !important; }
  .selectReg .react-select__control--is-focused:focus {
    border-color: #36aca3 !important; }

.selectSet .react-select__control--is-focused {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: #fff !important;
  background-image: linear-gradient(45deg, transparent 50%, #637381 50%), linear-gradient(135deg, #637381 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 4px), calc(100% - 15px) calc(1em + 4px), calc(100% - 2.5em) 0.5em;
  background-repeat: no-repeat;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  border: 1px solid #8996a2 !important;
  border-radius: 6px !important;
  box-shadow: none !important;
  color: #637381 !important;
  font-size: 14px;
  font-weight: normal;
  height: 40px;
  padding: 4px 16px;
  width: 152px; }
  .selectSet .react-select__control--is-focused:-webkit-autofill, .selectSet .react-select__control--is-focused:-webkit-autofill:hover, .selectSet .react-select__control--is-focused-webkit-autofill:focus, .selectSet .react-select__control--is-focused-webkit-autofill, .selectSet .react-select__control--is-focused-webkit-autofill:hover, .selectSet .react-select__control--is-focused-webkit-autofill:focus, .selectSet .react-select__control--is-focused-webkit-autofill, .selectSet .react-select__control--is-focused-webkit-autofill:hover, .selectSet .react-select__control--is-focused-webkit-autofill:focus {
    border: 1px solid #8996a2 !important;
    transition: background-color 5000s ease-in-out 0s !important; }
  .selectSet .react-select__control--is-focused:focus {
    border-color: #36aca3 !important; }

.selectSet .react-select__control {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: #fff !important;
  background-image: linear-gradient(45deg, transparent 50%, #637381 50%), linear-gradient(135deg, #637381 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 4px), calc(100% - 15px) calc(1em + 4px), calc(100% - 2.5em) 0.5em;
  background-repeat: no-repeat;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  border: 1px solid #8996a2 !important;
  border-radius: 6px !important;
  box-shadow: none !important;
  color: #637381 !important;
  font-size: 14px;
  font-weight: normal;
  height: 40px;
  padding: 4px 16px;
  width: 152px; }
  .selectSet .react-select__control:-webkit-autofill, .selectSet .react-select__control:-webkit-autofill:hover, .selectSet .react-select__control-webkit-autofill:focus, .selectSet .react-select__control-webkit-autofill, .selectSet .react-select__control-webkit-autofill:hover, .selectSet .react-select__control-webkit-autofill:focus, .selectSet .react-select__control-webkit-autofill, .selectSet .react-select__control-webkit-autofill:hover, .selectSet .react-select__control-webkit-autofill:focus {
    border: 1px solid #8996a2 !important;
    transition: background-color 5000s ease-in-out 0s !important; }
  .selectSet .react-select__control:focus {
    border-color: #36aca3 !important; }

.settings-table .react-select__control {
  border: 0 !important; }

.settings-table .style-input {
  border: 0 !important;
  height: 40px; }

.react-select__indicator-separator,
.react-select__indicators {
  display: none !important; }

.customeSelect .react-select__value-container {
  margin-left: 0;
  padding-left: 0; }

.select-error .react-select__control {
  border-color: #dc3824 !important;
  color: #dc3824; }

.error-block {
  color: #dc3824;
  font-size: 12px;
  font-weight: normal;
  line-height: 18px; }

@media (max-width: 920px) {
  .welcome-section-wrapper {
    padding-top: 47px; } }

@media (max-width: 767px) {
  h1 {
    font-size: 25px; }
  h2 {
    font-size: 22px; }
  h3 {
    font-size: 18px; }
  h4 {
    font-size: 16px; }
  h5 {
    font-size: 15px; }
  .container {
    padding: 0 10px; } }

@media (max-width: 480px) {
  .fixed {
    width: 100%; }
  .welcome-section-wrapper {
    margin: 0 -10px; }
  p {
    line-height: 1.2; } }

.chart-slider-wrapper {
  background-color: #fff;
  display: none;
  margin-top: 20px; }
  .chart-slider-wrapper .slick-arrow {
    top: 33.4%;
    z-index: 9; }
    .chart-slider-wrapper .slick-arrow:before {
      background-image: url("data:image/svg+xml,%3Csvg width='8' height='14' viewBox='0 0 8 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 12L5 7L0 2L1 0L8 7L1 14L0 12Z' fill='%2336ACA3'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-size: contain;
      content: "";
      display: -webkit-inline-flex;
      display: inline-flex;
      height: 14px;
      width: 8px; }
  .chart-slider-wrapper .slick-next {
    right: 4px; }
  .chart-slider-wrapper .slick-prev {
    left: 4px; }
    .chart-slider-wrapper .slick-prev:before {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }
  .chart-slider-wrapper .top-slider .chart {
    padding: 12px 40px; }
  .chart-slider-wrapper .slider-nav .slick-slide {
    border-top: 4px solid #fff;
    font-size: 16px;
    padding: 16px 0;
    text-align: center; }
    .chart-slider-wrapper .slider-nav .slick-slide.slick-current {
      border-color: #36aca3;
      color: #36aca3; }
  .chart-slider-wrapper .slider-nav .slick-list {
    margin: 0 auto;
    width: 85%; }
  .chart-slider-wrapper .slider-nav .slick-slider {
    box-shadow: 0 3px 12px rgba(134, 160, 158, 0.15); }
  .chart-slider-wrapper .slider-nav .slick-arrow {
    top: 48%; }
  .chart-slider-wrapper .chart-title {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-bottom: 12px; }
    .chart-slider-wrapper .chart-title .value {
      padding-left: 4px; }
  .chart-slider-wrapper .value {
    color: #4c5862;
    font-size: 12px; }
  .chart-slider-wrapper .label {
    color: #9ea3a8;
    font-size: 12px; }

.chart-content-list {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  .chart-content-list .list-item {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; }
    .chart-content-list .list-item .value {
      font-size: 16px; }

.chart-details {
  padding: 0 16px 16px; }

@media (max-width: 480px) {
  .chart-slider-wrapper {
    display: block; } }

