@import "../../../assets/scss/variables";

.button {
    align-items: center;
    background-color: $color-primary;
    border: 1px solid $color-primary;
    border-radius: $base-border-radius * 2;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    justify-content: center;
    line-height: 1;
    padding: 16px;
    transition: all 0.2s linear;
    width: 100%;

    &:hover {
        background-color: $color_primary_hover;
        border-color: $color_primary_hover;
        color: #fff;
    }

    &.btn-center {
        margin-left: auto;
        margin-right: auto;
    }

    &.small {
        max-width: 159px;
    }

    &.medium {
        max-width: 176px;
    }

    &.large {
        max-width: 200px;
    }

    &.with-icon {
        justify-content: space-evenly;

        &:hover {

            svg {

                path {
                    fill: #fff;
                }
            }
        }

        svg {
            margin-right: 14%;
        }
    }

    &:focus {
        outline: none;
    }

    &:disabled {
        background-color: transparent;
        color: $color-primary;
    }
}
