@import "../../../assets/scss/variables";

h3.title {
    color: $color_primary;
}

.loading-wrapper {
    bottom: 0;
    justify-content: center;
    left: 0;
    padding-bottom: 20px;
    padding-top: 25px;
    right: 0;
    top: 0;
}

@keyframes rotating {

    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.rotating {
    animation: rotating 2s linear infinite;
}
