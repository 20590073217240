@import "../../../assets/scss/variables";

.title {
    font-weight: 500;
    padding-left: 10px;

    .arrow-icon {
        border-color: #637381 transparent transparent;
        border-style: solid;
        border-width: 7px 7px 0;
        display: inline-block;
        height: 0;
        margin-left: 4px;
        width: 0;
    }

    &.opened {

        .arrow-icon {
            margin-bottom: 2px;
            transform: rotate(180deg);
        }
    }
}

.chart-dropdown {
    font-size: 16px;
    line-height: 24px;
    position: relative;
    width: fit-content;

    .list {
        border: 1px solid #ececec;
        position: absolute;
        right: 0;
        width: fit-content;
    }

    .list-item {
        appearance: none;
        background-color: #fff;
        border: 0;
        cursor: pointer;
        display: flex;
        font-size: 14px;
        padding: 16px;
        white-space: nowrap;
        width: 100%;

        &:focus {
            outline: none;
        }

        &:hover {
            background-color: #f2f9f7;
            color: $color_primary;
        }
    }
}
